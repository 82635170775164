import { useCurrentOrganization } from 'contexts/OrganizationContext'
import { React, useEffect } from 'react'

import { CenteredLoader } from '../../../components/centeredLoader'
import { Map } from '@outerspatial/outerspatial-map'
import { PageContent, PageHeader, PageLayout } from '../../../components/pageLayout'
import HelpDropdown from '../../../components/helpDropdown'

import '@outerspatial/outerspatial-map/dist/assets/outerspatial-map.css'
import './locationsMap.css'

const LocationsMap = () => {
  const organization = useCurrentOrganization()

  if (!organization) {
    return (<CenteredLoader />)
  }

  useEffect(() => {
    document.title = `Locations | ${organization.name} | ${process.env.REACT_APP_PAGE_TITLE}`
  })

  const bounds = (organization.extent && organization.extent.geometry) ? organization.extent.geometry : null

  const buildAreaClickUrl = (area) => {
    return `${document.location.pathname}/areas/${area.id}`
  }

  const handleFeatureInfoCardClick = (properties) => {
    const featureTypePath = (() => {
      switch (properties.class) {
        case 'Area':
          return 'areas'
        case 'Outing':
        case 'OutingEnd':
          return 'outings'
        case 'PointOfInterest':
          return 'points-of-interest'
        case 'Trail':
          return 'trails'
        case 'TrailSegment':
          return 'trails'
      }
    })()
    const id = properties.outing_id || properties.id

    window.open(`${document.location.pathname}/${featureTypePath}/${id}`)
  }

  const handleWalkthroughVideoSelect = () => {
    if (window.Beacon) {
      window.Beacon('article', '122', { type: 'sidebar' })
    } else {
      Object.assign(document.createElement('a'), { target: '_blank', href: 'https://organizations.outerspatial.help/article/122-locations-walkthrough-video' }).click()
    }
  }

  return (
    <div className='locations-map'>
      <PageLayout>
        <PageHeader>
          <div className='dashboard-header'>
            <div className='dashboard-header__title'>
              <div className='dashboard-header__titleText'>
                <h1>Locations</h1>
              </div>
              <div className='dashboard-header__titleHelp'>
                <HelpDropdown
                  handleWalkthroughVideoSelect={handleWalkthroughVideoSelect}
                />
              </div>
            </div>
          </div>
        </PageHeader>
        <PageContent>
          <div className='locations-map-wrapper'>
            <Map
              accessToken={`${process.env.REACT_APP_MAPBOX_KEY}`}
              bounds={bounds}
              hash
              onAreaClickUrlBuilder={buildAreaClickUrl}
              onFeatureInfoCardClick={handleFeatureInfoCardClick}
              organizationId={organization.id}
              outerSpatialApiUrl={process.env.REACT_APP_GRAPHQL_URL}
            />
          </div>
        </PageContent>
      </PageLayout>
    </div>
  )
}

export default LocationsMap
