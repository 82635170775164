import Button from 'react-bootstrap/lib/Button'
import Dropzone from 'react-dropzone'
import Panel from 'react-bootstrap/lib/Panel'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Select from 'react-select'

import { buildImageUploadUrl } from 'services/Images'
import { timezones as timezoneOptions } from 'models/time.model'
import { useFormikContext } from 'formik'
import FormikGroup from 'components/fieldGroup/FormikGroup'
import HelpDropdown from 'components/helpDropdown'
import ImageCropperModal from 'components/image/cropper/ImageCropperModal'
import MediaModal from 'components/media/modal/MediaModal'

const OrganizationProfileOverview = ({
  isOrganizationAdmin,
  isSuperAdmin,
  organization
}) => {
  const { submitForm, dirty, isValid, setFieldValue, setFieldTouched } = useFormikContext()
  const [newLogoImage, setNewLogoImage] = useState()
  const [previewImage, setPreviewImage] = useState(null)
  const [showImageCropperModal, setShowImageCropperModal] = useState(false)
  const [showMediaModal, setShowMediaModal] = useState(false)
  const [timezoneSelectValue, setTimezoneSelectValue] = useState(timezoneOptions.filter((i) => i.value === organization.time_zone)[0])
  let logoUrl = null

  const attachLogo = (image) => {
    setNewLogoImage(image)
    setFieldValue('logo_image_id', image.id)
    setFieldTouched('logo_image_id', true)
    closeMediaModal()
  }

  const closeMediaModal = () => {
    setShowMediaModal(false)
  }

  const handleDropped = (dropped) => {
    setPreviewImage(dropped[0])
    setShowImageCropperModal(true)
  }

  const handleEditAddLogo = () => {
    if (isSuperAdmin) {
      setShowImageCropperModal(true)
    } else if (isOrganizationAdmin) {
      window.Beacon && window.Beacon('article', '68', { type: 'sidebar' })
    }
  }

  const handleOrganizationLogoUpdated = (image) => {
    attachLogo(image)
  }

  const handleWalkthroughVideoSelect = () => {
    if (window.Beacon) {
      window.Beacon('article', '123', { type: 'sidebar' })
    } else {
      Object.assign(document.createElement('a'), { target: '_blank', href: 'https://organizations.outerspatial.help/article/123-organization-walkthrough-video' }).click()
    }
  }

  const onTimezoneSelected = (e) => {
    setFieldValue('time_zone', e.value)
    setFieldTouched('time_zone', true)
    setTimezoneSelectValue(timezoneOptions.filter((i) => i.value === e.value)[0])
  }

  const openMediaModal = () => {
    setShowMediaModal(true)
  }

  if (newLogoImage) {
    const image = newLogoImage

    if (image.uploaded_file) {
      logoUrl = buildImageUploadUrl(image.id, image.uploaded_file, 'medium_square')
    }
  } else if (organization.logo_image && organization.logo_image.id) {
    const image = organization.logo_image

    if (image.uploaded_file) {
      logoUrl = buildImageUploadUrl(image.id, image.uploaded_file, 'medium_square')
    }
  }

  return (
    <div>
      <Panel>
        {(isOrganizationAdmin || isSuperAdmin) &&
          <Panel.Heading>
            <div
              className='pull-right'
            >
              <HelpDropdown
                handleWalkthroughVideoSelect={handleWalkthroughVideoSelect}
              />
              <Button
                bsStyle='primary'
                className='pull-right'
                disabled={!(dirty && isValid)}
                form='organization-form'
                onClick={submitForm}
              >
                Save
              </Button>
            </div>
          </Panel.Heading>}
        <Panel.Body>
          <div className={'form-group organizationInfo-logo ' + (logoUrl ? 'organizationInfo--hasLogo' : '')}>
            <>
              <label className='control-label' htmlFor='organization-logo-input'>Organization Logo</label>
              {isSuperAdmin && (
                <>
                  <Dropzone
                    onDrop={(dropped) => handleDropped(dropped)}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()}>
                        <input id='organization-logo-input' {...getInputProps()} />
                        {logoUrl && (
                          <div className='organizationInfo-logoImage' style={{ backgroundImage: `url(${logoUrl})` }} />
                        )}
                        {!logoUrl && (
                          <div className='organizationInfo-logoImage' />
                        )}
                      </div>
                    )}
                  </Dropzone>
                  <span className='help-block'>Drag-and-drop an image to change the logo or
                    {' '}
                    <a
                      onClick={() => openMediaModal()}
                    >
                      click here
                    </a>
                    {' '}
                    to select an image from the gallery.
                  </span>
                </>
              )}
              {!isSuperAdmin && logoUrl && (
                <div
                  className='organizationInfo-logoImage'
                  style={{ backgroundImage: `url(${logoUrl})` }}
                />
              )}
              {!isSuperAdmin && !logoUrl && (
                <div className='organizationInfo-logoImage' />
              )}
              {!isSuperAdmin && isOrganizationAdmin && (
                <Button
                  className='organizationInfo-logoButton'
                  onClick={() => handleEditAddLogo()} bsSize='small'
                >
                  Request Logo Update
                </Button>
              )}
            </>
          </div>
          <FormikGroup
            disabled={!isSuperAdmin}
            id='name'
            label='Name *'
            name='name'
            type='text'
          />
          <FormikGroup
            disabled={!isOrganizationAdmin && !isSuperAdmin}
            disabledWysiwygContent={organization.description}
            id='description'
            label='Description'
            name='description'
            type='wysiwyg'
          />
          <fieldset>
            <label htmlFor='timezoneSelect'>Default Timezone</label>
            <Select
              className='eventForm-timezoneSelect'
              isClearable={false}
              isDisabled={!isOrganizationAdmin && !isSuperAdmin}
              name='time_zone'
              onChange={onTimezoneSelected}
              options={timezoneOptions}
              value={timezoneSelectValue}
            />
          </fieldset>
        </Panel.Body>
      </Panel>
      <ImageCropperModal
        modalOpen={showImageCropperModal}
        onSuccess={handleOrganizationLogoUpdated}
        setModalOpen={setShowImageCropperModal}
        src={previewImage}
      />
      <MediaModal
        allowSelection
        onClose={() => closeMediaModal()}
        onSubmit={(selected) => attachLogo(selected[0])}
        show={showMediaModal}
        singleSelection
      />
    </div>
  )
}

OrganizationProfileOverview.propTypes = {
  organization: PropTypes.object,
  isOrganizationAdmin: PropTypes.bool,
  isSuperAdmin: PropTypes.bool
}

export default OrganizationProfileOverview
